import { useQuery } from '@tanstack/react-query';
import { getCourseModuleLectures } from '../../../../Utilities/api';
import { useContext } from 'react';
import { AdminRoutesContext } from '../AdminRoutesContext';
export const useCourseModuleLectures = (courseUuid, courseModuleUuid) => {
    const useAdminRoutes = useContext(AdminRoutesContext);
    return useQuery({
        queryKey: ['courseModuleLectures', courseModuleUuid],
        queryFn: () => getCourseModuleLectures(courseUuid, courseModuleUuid, useAdminRoutes.useAdminRoutes),
    });
};
