import { useQuery } from '@tanstack/react-query';
import { getLectureDetail } from '../../../../Utilities/api';
import { useContext } from 'react';
import { AdminRoutesContext } from '../AdminRoutesContext';
export const useLecture = (courseUuid, moduleUuid, lectureUuid) => {
    const useAdminRoutes = useContext(AdminRoutesContext);
    return useQuery({
        queryKey: ['lecture', lectureUuid],
        queryFn: () => getLectureDetail(courseUuid, moduleUuid, lectureUuid, useAdminRoutes.useAdminRoutes),
    });
};
