import { useQuery } from '@tanstack/react-query';
import { getCourseDetail } from '../../../../Utilities/api';
import { useContext } from 'react';
import { AdminRoutesContext } from '../AdminRoutesContext';
export const useCourse = (courseUuid) => {
    const useAdminRoutes = useContext(AdminRoutesContext);
    return useQuery({
        queryKey: ['course', courseUuid],
        queryFn: () => getCourseDetail(courseUuid, useAdminRoutes.useAdminRoutes),
    });
};
