import { useQuery } from '@tanstack/react-query';
import { getCourseModulesList } from '../../../../Utilities/api';
import { useContext } from 'react';
import { AdminRoutesContext } from '../AdminRoutesContext';
export const useCourseModules = (courseUuid) => {
    const useAdminRoutes = useContext(AdminRoutesContext);
    return useQuery({
        queryKey: ['courseModules', courseUuid],
        queryFn: () => getCourseModulesList(courseUuid, useAdminRoutes.useAdminRoutes),
    });
};
